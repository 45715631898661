import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'amc-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent {
  @Output() CreateNewEntity: EventEmitter<string> = new EventEmitter<string>();
  isCreateMaximized: boolean;
  @Input() Entities: any;

  constructor() {
    this.isCreateMaximized = true;
  }
  createNewEntity(type: string) {
    this.CreateNewEntity.emit(type);
  }

  getEntities() {
    return Object.keys(this.Entities);
  }

  getDisplay(entity) {
      return entity.substring(0, entity.indexOf('|'));
  }

  getImage(entity) {
      return entity.substring(entity.indexOf('|') + 1);
  }
}
