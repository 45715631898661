import { ERROR_CODE } from './ErrorCode';
import { LOG_LEVEL } from './LogLevel';

export class LogObj {
  public errorCode: number;
  public logLevel: string;
  public localTime: Date;
  public message: string;
  public logLevel_set: string;
  public tabId: string;
  public threadId: string;

  constructor(
    errorCode: ERROR_CODE,
    message: string,
    logLevel: LOG_LEVEL,
    logLevelSet: LOG_LEVEL,
    tabId: string,
    threadId: string = '',
    localTime: Date = new Date()
  ) {
    this.errorCode = errorCode;
    this.message = message;
    this.logLevel = logLevel.toString();
    this.logLevel_set = logLevelSet.toString();
    this.tabId = tabId;
    this.threadId = threadId;
    this.localTime = localTime;
  }
}
