import { ILoginData } from './../../models/login.interface';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'amc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @Input() loginData: ILoginData;
  @Output() loginDetailsProvided = new EventEmitter<ILoginData>();

  constructor() { }

  ngOnInit() {
    if (!this.loginData.header) {
      this.loginData.header = 'Login';
    }

    for (let field of this.loginData.fields) {
      if (!field.isInvalid) {
        field.isInvalid = false;
      }
    }
  }

  onLogin(loginForm: NgForm) {
    for (const field of this.loginData.fields) {
      field.value = loginForm.value[field.name];
    }

    this.loginDetailsProvided.emit(this.loginData);
  }

  focusOutOfInput(loginForm: NgForm, field) {
    if (!loginForm.controls[field.name].valid) {
      field.isInvalid = true;
    }
  }
}
