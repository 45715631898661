import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InteractionComponent } from './components/interaction/interaction.component';
import { ScenarioComponent } from './components/scenario/scenario.component';
import { OperationComponent } from './components/operation/operation.component';
import { PropertyComponent } from './components/property/property.component';
import { HoldtimerComponent } from './components/holdtimer/holdtimer.component';
import { DurationComponent } from './components/duration/duration.component';
import { ChatBoxComponent } from './components/chat-box/chat-box.component';
import { ChatMessageComponent } from './components/chat-message/chat-message.component';
import { FormsModule } from '@angular/forms';
import { createCustomElement } from '@angular/elements';
import { BrowserModule } from '@angular/platform-browser';
import { ElementZoneStrategyFactory } from 'elements-zone-strategy';
import { DispositionComponent } from './components/disposition/disposition.component';
import { ActivityComponent } from './components/activity/activity.component';
import { LoginComponent } from './components/login/login.component';
import { CreateComponent } from './components/create/create.component';
import { RecentActivityComponent } from './components/recent-activity/recent-activity.component';
import { CurrentActivityComponent } from './components/current-activity/current-activity.component';
import { SearchInformationComponent } from './components/search-information/search-information.component';
import { MatTabsModule } from "@angular/material/tabs";

@NgModule({
  imports: [BrowserModule, CommonModule, FormsModule,MatTabsModule],
  declarations: [
    InteractionComponent,
    ScenarioComponent,
    OperationComponent,
    PropertyComponent,
    HoldtimerComponent,
    DurationComponent,
    ChatBoxComponent,
    ChatMessageComponent,
    DispositionComponent,
    ActivityComponent,
    LoginComponent,
    CreateComponent,
    RecentActivityComponent,
    CurrentActivityComponent,
    SearchInformationComponent
  ],
  providers:[],
  entryComponents: [ScenarioComponent, DispositionComponent, LoginComponent, CreateComponent, RecentActivityComponent, CurrentActivityComponent, SearchInformationComponent],
  bootstrap: [],
  exports: [ScenarioComponent, DispositionComponent, LoginComponent, CreateComponent, RecentActivityComponent, CurrentActivityComponent, SearchInformationComponent]
})
export class UILibraryModule {
  constructor(private injector: Injector) { }

  ngDoBootstrap(app) {
    if (!customElements.get('amc-webcomponents-scenario')) {
      const strategyFactory = new ElementZoneStrategyFactory(
        ScenarioComponent,
        this.injector
      );
      customElements.define(
        'amc-webcomponents-scenario',
        createCustomElement(ScenarioComponent, {
          injector: this.injector,
          strategyFactory: strategyFactory
        })
      );
    }
    if (!customElements.get('amc-webcomponents-disposition')) {
      const strategyFactory = new ElementZoneStrategyFactory(
        DispositionComponent,
        this.injector
      );
      customElements.define(
        'amc-webcomponents-disposition',
        createCustomElement(DispositionComponent, {
          injector: this.injector,
          strategyFactory: strategyFactory
        })
      );
    }
    if (!customElements.get('amc-webcomponents-login')) {
      const strategyFactory = new ElementZoneStrategyFactory(
        LoginComponent,
        this.injector
      );
      customElements.define(
        'amc-webcomponents-login',
        createCustomElement(LoginComponent, {
          injector: this.injector,
          strategyFactory: strategyFactory
        })
      );
    }
  }
}

export * from './models/uilibrary.model';
