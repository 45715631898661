import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';
import * as api from '@amc-technology/davinci-api';
import { IActivity } from '../../models/IActivity';

@Component({
  selector: 'amc-search-information',
  templateUrl: './search-information.component.html',
  styleUrls: ['./search-information.component.css']
})
export class SearchInformationComponent implements OnChanges {
  @Input() ActivityDetails: IActivity;
  @Input() searchLayout: api.SearchLayouts;
  @Input() searchRecordList: Array<api.IRecordItem>;
  @Output() agentSelectedCallerInformation: EventEmitter<{
    id: string;
    value: string;
  }>;

  isSearchInformationMaximized: boolean;
  imageLocation: string;
  singleMatchIconSrc: string;
  singleMatchData: any;
  multiMatchData: any[];
  shouldShowAllMultiMatchOptions: boolean;

  constructor() {
    this.isSearchInformationMaximized = true;
    this.singleMatchData = null;
    this.multiMatchData = [];
    this.agentSelectedCallerInformation = new EventEmitter();
  }

  ngOnChanges() {
    this.renderData();
  }

  protected renderData() {
    try {
      this.singleMatchData = null;
      this.multiMatchData = [];
      this.shouldShowAllMultiMatchOptions = false;
      if (this.searchRecordList.length === 1) {
        this.singleMatchData = this.parseSearchRecordForNameSingleMatch(
          this.searchRecordList[0]
        );
      } else if (this.searchRecordList.length > 1) {
        for (let i = 0; i < this.searchRecordList.length; i++) {
          this.multiMatchData.push(
            this.parseSearchRecordForNameMultiMatch(this.searchRecordList[i])
          );
        }
      }
    } catch (error) {}
  }

  onAgentSelectedCallerInformation(event: any) {
    try {
      this.agentSelectedCallerInformation.emit({
        id: event.target.id,
        value: event.target.value
      });
    } catch (error) {}
  }

  protected parseSearchRecordForNameSingleMatch(searchRecord: api.IRecordItem) {
    const results = [];
    try {
      const src = this.getEntityImgToDisplay(searchRecord);
      this.singleMatchIconSrc = src;
      const sLayoutInfo = this.getSearchLayoutInfoForDisplay(searchRecord);
      for (let j = 0; j < sLayoutInfo.DisplayFields.length; j++) {
        if (sLayoutInfo.DisplayFields && sLayoutInfo.DisplayFields[j].DevName) {
          const nameKey = sLayoutInfo.DisplayFields[j].DevName;
          const keys = Object.keys(searchRecord.fields);
          for (let i = 0; i < keys.length; i++) {
            if (
              searchRecord.fields[keys[i]] &&
              searchRecord.fields[keys[i]].DevName === nameKey
            ) {
              let displayRecord = searchRecord.fields[keys[i]].Value;
              if (j === 0) {
                displayRecord = searchRecord.displayName
                  ? [searchRecord.displayName, displayRecord]
                  : [searchRecord.type, displayRecord];
              } else {
                displayRecord = sLayoutInfo.DisplayFields[j].DisplayName
                  ? [sLayoutInfo.DisplayFields[j].DisplayName, displayRecord]
                  : [sLayoutInfo.DisplayFields[j].DevName, displayRecord];
              }
              results.push(displayRecord);
            }
          }
        }
      }
    } catch (error) {}
    return results;
  }

  protected parseSearchRecordForNameMultiMatch(searchRecord: api.IRecordItem) {
    const results = [];
    try {
      const src = this.getEntityImgToDisplay(searchRecord);
      const sLayoutInfo = this.getSearchLayoutInfoForDisplay(searchRecord);
      for (let j = 0; j < sLayoutInfo.DisplayFields.length; j++) {
        if (sLayoutInfo.DisplayFields && sLayoutInfo.DisplayFields[j].DevName) {
          const nameKey = sLayoutInfo.DisplayFields[j].DevName;
          const keys = Object.keys(searchRecord.fields);
          for (let i = 0; i < keys.length; i++) {
            if (
              searchRecord.fields[keys[i]] &&
              searchRecord.fields[keys[i]].DevName === nameKey
            ) {
              let displayRecord = searchRecord.fields[keys[i]].Value;
              if (j === 0) {
                displayRecord = searchRecord.displayName
                  ? [searchRecord.displayName, displayRecord]
                  : [searchRecord.type, displayRecord];
              } else {
                displayRecord = sLayoutInfo.DisplayFields[j].DisplayName
                  ? [sLayoutInfo.DisplayFields[j].DisplayName, displayRecord]
                  : [sLayoutInfo.DisplayFields[j].DevName, displayRecord];
              }
              displayRecord.push(src);
              results.push(displayRecord);
              return results;
            }
          }
        }
      }
    } catch (error) {}
    return results;
  }

  protected getEntityImgToDisplay(searchRecord: api.IRecordItem) {
    let src = '';
    try {
      if (searchRecord.type) {
        if (searchRecord.type.toUpperCase() === 'CONTACT') {
          src = '../../assets/images/Icon_Contact.png';
        } else if (searchRecord.type.toUpperCase() === 'ACCOUNT') {
          src = '../../assets/images/Icon_Account.png';
        } else if (searchRecord.type.toUpperCase() === 'LEAD') {
          src = '../../assets/images/Icon_Lead.png';
        } else {
          src = '../../assets/images/Miscellaneous_Icon.png';
        }
      }
    } catch (error) {}
    return src;
  }

  protected getSearchLayoutInfoForDisplay(searchRecord: api.IRecordItem) {
    let layoutInfo: any;
    try {
      layoutInfo = this.searchLayout.layouts[0][
        this.ActivityDetails.CallType
      ].find((i) => i.DevName === searchRecord.type);
    } catch (error) {}
    return layoutInfo;
  }
}
