import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ValueType, IProperty } from '../../models/uilibrary.model';
import { secondsToHms } from '../../Helpers';

/**
 * @ignore
 */
@Component({
  selector: 'amc-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss', './../uilibrary.module.scss']
})
export class PropertyComponent implements OnInit, OnDestroy {

  @Input() statusText: string;
  @Input() callId: string;
  @Input() callType: string;
  @Input() property: IProperty;

  labelledByText = '';
  title = '';

  /**
   * @ignore
   */
  processedDisplayValue: string;

  /**
   * @ignore
   */
  private _timerId?: number;

  /**
   * @ignore
   */
  constructor() {

    this._timerId = null;
    this.processedDisplayValue = '';
  }

  /**
   * @ignore
   */
  ngOnInit() {
    if (this.statusText && this.callType) {
      this.labelledByText = `${this.statusText} ${this.callType}`;
    } else if (this.statusText) {
      this.labelledByText = this.statusText;
    } else if (this.callType) {
      this.labelledByText = this.callType
    }

    if (this.callType && this.callId) {
      this.title = `${this.callType} ${this.callId}`
    } else if (this.callType) {
      this.title = this.callType;
    } else if (this.callId) {
      this.title = this.callId;
    }

    this.initValues();
  }

  /**
   * @ignore
   */
  initValues(): void {
    if (this.property.displayValueType === ValueType.COUNTER) {
      this.startCallCounter();
    } else {
      this.processedDisplayValue = this.property.displayValue;
    }
  }

  /**
   * @ignore
   */
  private startCallCounter(): void {
    if (this._timerId == null) {
      this._timerId = window.setInterval(() => {
        const callStartTime: number = this.property.displayValue;
        const currentTime: number = new Date().getTime() / 1000;
        const secondsPassed: number = Math.floor((currentTime) - (callStartTime / 1000));
        this.processedDisplayValue = secondsToHms(secondsPassed);
      }, 1000);
    }
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    if (this.property.displayValueType === ValueType.COUNTER) {
      // clear the timer.
      if (this._timerId != null) {
        clearInterval(this._timerId);
        this._timerId = null;
      }
    }
  }
}
