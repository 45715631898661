import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ERROR_CODE, LOG_LEVEL } from '@amc-technology/davinci-api';

import { BehaviorSubject } from 'rxjs';
import { IActivity } from '../Model/Interfaces/IActivity';
import { IActivityDetails } from '../Model/Interfaces/IActivityDetails';
import { LoggerService } from '../logger.service';
import { StorageService } from '../storage.service';

@Component({
  selector: 'app-recent-activities',
  templateUrl: './recentActivities.component.html',
  styleUrls: ['./recentActivities.component.css']
})
export class RecentActivitiesComponent {
  log: (logLevel: LOG_LEVEL, fName: string, message: string, object?: any, errorCode?: ERROR_CODE, localTime?: Date) => void;

  @Input() quickCommentList: string[];
  @Input() isInConsoleView: boolean;
  @Output() saveActivity: EventEmitter<string> = new EventEmitter<string>();
  @Output() getRecentWorkItem: EventEmitter<string> = new EventEmitter<string>();

  @Output() screenpopWorkItem: EventEmitter<string> = new EventEmitter();

  collapseToggle: boolean;
  private _activities$: BehaviorSubject<IActivity[]>;
  private _previousActivities: string;

  constructor(private loggerService: LoggerService, public storageService: StorageService) {
    this.log = loggerService.log;
    this.collapseToggle = true;
    this._activities$ = new BehaviorSubject([]);
    this._previousActivities = '';
  }

  getRecentScenarios() {
    const result: IActivity[] = [];
    for (const scenarioID of this.storageService.recentScenarioIdList) {
      result.push(this.storageService.activityList[scenarioID]);
    }
    const newActivities = JSON.stringify(result);
    if (newActivities === this._previousActivities) {
      this._previousActivities = newActivities;
      return JSON.parse(this._previousActivities);
    }
  }

  getEvent(event: { eventName: string; idx: number; newValue?: IActivity }) {
    const eventList = event.eventName.split('|');
    for (const eventName of eventList) {
      switch (eventName) {
        case 'WorkingEvtivityChanged':
          this.expandAndCollapseRecentActivity(event.idx !== -1, event.newValue.ScenarioId);
          break;
        case 'OpenCallActivity':
          this.openActivity(event.newValue.ScenarioId);
          break;
        case 'ActivitySubjectChanged':
          this.onSubjectChange(event.newValue);
          break;
        case 'ActivityWhoObjectChanged':
          this.onNameChange(event.newValue);
          break;
        case 'ActivityWhatObjectChanged':
          this.onRelatedToChange(event.newValue);
          break;
        case 'ActivityCallNoteChanged':
          this.onCallNotesChange(event.newValue);
          break;
        case 'SubmitActivity':
          this.submitActivity(event.newValue);
          break;
        default:
      }
    }
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  protected submitActivity(data: IActivity) {
    this.loggerService.logger.logTrace('Salesforce - Recent Activity : START : Submit Activity. Scenario ID : ' + data.ScenarioId);
    try {
      this.storageService.activityList[data.ScenarioId].IsProcessing = true;
      this.saveActivity.emit(data.ScenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Recent Activity : ERROR : Submit Activity. Scenario ID : ' + data.ScenarioId + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Salesforce - Recent Activity : END : Submit Activity. Scenario ID : ' + data.ScenarioId);
  }

  protected retrieveActivity(scenarioId: string) {
    this.loggerService.logger.logTrace('Salesforce - Recent Activity : START : Retrieve Activity. Scenario ID : ' + scenarioId);
    try {
      this.getRecentWorkItem.emit(scenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Recent Activity : ERROR : Retrieve Activity. Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Salesforce - Recent Activity : END : Retrieve Activity. Scenario ID : ' + scenarioId);
  }

  protected openActivity(scenarioId: string) {
    this.loggerService.logger.logTrace('Salesforce - Recent Activity : START : Open Activity. Scenario ID : ' + scenarioId);
    try {
      this.screenpopWorkItem.emit(this.storageService.activityList[scenarioId].ActivityId);
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Recent Activity : ERROR : Open Activity. Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Salesforce - Recent Activity : END : Open Activity. Scenario ID : ' + scenarioId);
  }

  protected expandAndCollapse(isExpand: boolean) {
    if (isExpand) {
      this.collapseToggle = true;
    } else {
      this.collapseToggle = false;
    }
  }

  protected async expandAndCollapseRecentActivity(isExpand: boolean, scenarioId: string) {
    try {
      this.loggerService.logger.logTrace('Salesforce - Recent Activity : START : Expand/Collapse Activity. Scenario ID : ' + scenarioId + ', IsExpand : ' + isExpand);
      if (isExpand) {
        this.storageService.workingRecentScenarioId = scenarioId;
        if (!this.storageService.activityList[scenarioId].IsUnSaved) {
          this.storageService.activityList[scenarioId].IsRecentWorkItemLoading = true;
          this.retrieveActivity(scenarioId);
        }
      } else {
        this.storageService.workingRecentScenarioId = null;
      }
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Recent Activity : ERROR : Expand/Collapse Activity. Scenario ID : ' + scenarioId + ', IsExpand : ' + isExpand + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Salesforce - Recent Activity : END : Expand/Collapse Activity. Scenario ID : ' + scenarioId + ', IsExpand : ' + isExpand);
  }

  protected onNameChange(data: IActivity) {
    this.loggerService.logger.logTrace('Salesforce - Recent Activity : START : On Name Change. Input : ' + JSON.stringify(data));
    try {
      this.storageService.selectedWhoValueList[data.ScenarioId] = data.WhoObject.objectId;
      this.storageService.UpdateWhoObjectSelectionChange(data.WhoObject.objectId, data.ScenarioId);
      this.storageService.compareActivityFields(this.storageService.workingRecentScenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Recent Activity : ERROR : On Name Change. Input : ' + JSON.stringify(data) + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Salesforce - Recent Activity : END : On Name Change. Input : ' + JSON.stringify(data));
  }

  protected onRelatedToChange(data: IActivity) {
    this.loggerService.logger.logTrace('Salesforce - Recent Activity : START : On Related To Change. Input : ' + JSON.stringify(data));
    try {
      this.storageService.selectedWhatValueList[data.ScenarioId] = data.WhatObject.objectId;
      this.storageService.UpdateWhatObjectSelectionChange(data.WhatObject.objectId, data.ScenarioId);
      this.storageService.compareActivityFields(this.storageService.workingRecentScenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Recent Activity : ERROR : On Related To Change. Input : ' + JSON.stringify(data) + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Salesforce - Recent Activity : END : On Related To Change. Input : ' + JSON.stringify(data));
  }

  protected onSubjectChange(recentActivity: IActivity) {
    this.loggerService.logger.logTrace('Salesforce - Recent Activity : START : On Subject Change. Input : ' + JSON.stringify(recentActivity));
    try {
      this.storageService.setSubject(recentActivity.Subject, this.storageService.workingRecentScenarioId);
      this.storageService.compareActivityFields(this.storageService.workingRecentScenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Recent Activity : ERROR : On Subject Change. Input : ' + JSON.stringify(event) + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Salesforce - Recent Activity : END : On Subject Change. Input : ' + JSON.stringify(recentActivity));
  }

  protected onCallNotesChange(data: IActivity) {
    const functionName = 'onCallNotesChange';
    try {
      this.log(LOG_LEVEL.Trace, functionName, 'Updating Call Notes for recent activity.', { dataScenarioId: data.ScenarioId, dataDescription: data.Description, activeScenarioId: this.storageService.workingRecentScenarioId });
      this.storageService.setDescription(data.Description, data.ScenarioId);
      this.storageService.compareActivityFields(data.ScenarioId);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to update Call Notes for recent activity.', error);
    }
  }

  protected parseWhoObject(whoObject: IActivityDetails): string {
    this.loggerService.logger.logTrace('Salesforce - Recent Activity : Parsing Who Object. Input : ' + JSON.stringify(whoObject));
    try {
      return (whoObject.displayName ? whoObject.displayName : 'Entity') + ': ' + whoObject.objectName;
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Recent Activity : ERROR : Parsing Who Object. Input : ' + JSON.stringify(whoObject) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  protected parseWhatObject(whatObject: IActivityDetails): string {
    this.loggerService.logger.logTrace('Salesforce - Recent Activity : Parsing What Object. Input : ' + JSON.stringify(whatObject));
    try {
      return (whatObject.displayName ? whatObject.displayName : 'Entity') + ': ' + whatObject.objectName;
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Recent Activity : ERROR : Parsing What Object. Input : ' + JSON.stringify(whatObject) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  protected loadQuickComment(comment: string, scenarioId: string) {
    this.loggerService.logger.logTrace('Salesforce - Recent Activity : START : Load Quick Comments. Input : ' + comment);
    try {
      const descriptionToSet = this.quickCommentList[comment];
      const descriptionValue = this.storageService.activityList[scenarioId].Description;
      if (!descriptionValue) {
        this.storageService.setDescription(descriptionToSet, scenarioId);
      } else {
        this.storageService.setDescription(descriptionValue + '\n' + descriptionToSet, scenarioId);
      }
      this.storageService.compareActivityFields(scenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Recent Activity : ERROR : Load Quick Comments. Input : ' + comment + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Salesforce - Recent Activity : END : Load Quick Comments. Input : ' + comment);
  }
}
