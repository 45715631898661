import { APP_INITIALIZER, NgModule } from '@angular/core';

import { ActivitySalesforceComponent } from './activity/activity-salesforce.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ConfigurationService } from './configuration.service';
import { CreateSalesforceComponent } from './create/create-salesforce.component';
import { CreateV2Component } from './create-v2/create-v2.component';
import { FormsModule } from '@angular/forms';
import { HomeSalesforceComponent } from './home/home-salesforce.component';
import { HttpClientModule } from '@angular/common/http';
import { LoggerService } from './logger.service';
import { MatCommonModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RecentActivitiesComponent } from './recentactivities/recentactivities.component';
import { RouterModule } from '@angular/router';
import { SalesforceComponent } from './salesforce.component';
import { SearchInformationSalesforceComponent } from './search-information-salesforce/search-information-salesforce.component';
import { StorageService } from './storage.service';
import { UILibraryModule } from '@amc-technology/ui-library';

@NgModule({
  declarations: [SalesforceComponent, HomeSalesforceComponent, ActivitySalesforceComponent, CreateSalesforceComponent, SearchInformationSalesforceComponent, RecentActivitiesComponent, CreateV2Component],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    UILibraryModule,
    RouterModule.forRoot([{ path: '', component: HomeSalesforceComponent, pathMatch: 'full' }]),
    NgbModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatInputModule,
    MatTabsModule,
    MatSelectModule,
    MatExpansionModule,
    MatCommonModule
  ],
  providers: [
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigurationService, loggerService: LoggerService) => async () => {
        await configService.loadConfigurationData();
        await loggerService.initialize();
      },
      deps: [ConfigurationService, LoggerService],
      multi: true
    },
    LoggerService,
    StorageService
  ],
  bootstrap: [SalesforceComponent]
})
export class AppModule {}
