import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IActivityDetails } from '../../models/IActivityDetails';
import { IActivity } from '../../models/IActivity';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'amc-current-activity',
  templateUrl: './current-activity.component.html',
  styleUrls: ['./current-activity.component.css']
})
export class CurrentActivityComponent implements OnInit {
  @Input() ActivityDetails: IActivity;
  @Input() quickCommentList: string[];
  @Input() isAutoSave: boolean;
  @Input() enableDiscard: boolean;
  @Input() autoSaveTimer: number;
  @Input() quickCommentOptionRequiredCadArray: any;
  @Input() WhoObjectList: IActivityDetails[];
  @Input() WhatObjectList: IActivityDetails[];
  @Input() scenarioToCADMap: {};
  @Output() EventEmitter: EventEmitter<{
    eventName: string;
    newValue?: IActivity;
  }>;

  private eventList: Set<String>;
  private debouncer: Subject<void>;

  isActivityMaximized: boolean;

  constructor() {
    this.isActivityMaximized = true;

    this.EventEmitter = new EventEmitter<{
      eventName: string;
      newValue?: IActivity;
    }>();

    this.debouncer = new Subject();
    this.eventList = new Set();
  }

  async ngOnInit() {
    this.debouncer.pipe(debounceTime(500)).subscribe(() => {
      const newEvent = {
        eventName: Array.from(this.eventList).join('|'),
        newValue: this.ActivityDetails
      };
      this.EventEmitter.emit(newEvent);
      this.eventList.clear();
    });
  }

  submitActivity() {
    try {
      this.ActivityDetails.IsProcessing = true;
      this.EventEmitter.emit({
        eventName: 'SubmitActivity',
        newValue: this.ActivityDetails
      });
    } catch (error) {}
  }

  triggerDiscardActivity() {
    try {
      this.EventEmitter.emit({
        eventName: 'TriggerDiscardActivity',
        newValue: this.ActivityDetails
      });
    } catch (error) {}
  }

  onNameChange(event: any) {
    try {
      this.ActivityDetails.WhoObject = this.WhoObjectList.find((obj) => obj.objectId === event);
      this.EventEmitter.emit({
        eventName: 'ActivityWhoObjectChanged',
        newValue: this.ActivityDetails
      });
    } catch (error) {}
  }

  onRelatedToChange(event: any) {
    try {
      this.ActivityDetails.WhatObject = this.WhatObjectList.find((obj) => obj.objectId === event);
      this.EventEmitter.emit({
        eventName: 'ActivityWhatObjectChanged',
        newValue: this.ActivityDetails
      });
    } catch (error) {}
  }

  onSubjectChange() {
    try {
      this.eventList.add('ActivitySubjectChanged');
      this.debouncer.next();
    } catch (error) {}
  }

  onSubjectKeyUp() {
    try {
      this.eventList.add('ActivitySubjectChanged');
      this.debouncer.next();
    } catch (error) {}
  }

  onCallNotesChange() {
    try {
      this.eventList.add('ActivityCallNoteChanged');
      this.debouncer.next();
    } catch (error) {}
  }

  onCallNotesKeyUp() {
    try{
      this.eventList.add('ActivityCallNoteChanged');
      this.debouncer.next();
    } catch (error) {}
  }

  addQuickCommentToDescription(comment: string) {
    try {
      let descriptionToSet = comment;
      if (this.quickCommentOptionRequiredCadArray[comment]) {
        let cadFields = {};
        if (this.ActivityDetails) {
          cadFields = this.scenarioToCADMap[this.ActivityDetails.ScenarioId];
        }
        for (
          let i = 0;
          i < this.quickCommentOptionRequiredCadArray[comment].length;
          i++
        ) {
          let keyToCheckIfCADExists =
            this.quickCommentOptionRequiredCadArray[comment][i];
          const stringToBeReplaced =
            this.quickCommentOptionRequiredCadArray[comment][i];
          keyToCheckIfCADExists = keyToCheckIfCADExists.replace('{{', '');
          keyToCheckIfCADExists = keyToCheckIfCADExists.replace('}}', '');
          if (cadFields[keyToCheckIfCADExists]) {
            descriptionToSet = descriptionToSet.replace(
              stringToBeReplaced,
              cadFields[keyToCheckIfCADExists].Value
            );
          }
        }
      }
      if (this.ActivityDetails.Description) {
        this.ActivityDetails.Description += '\n';
      }
      this.ActivityDetails.Description += descriptionToSet;
      this.eventList.add('ActivityCallNoteChanged');
      this.debouncer.next();
    } catch (error) {}
  }

  protected parseWhoObject(whoObject: IActivityDetails): string {
    try {
      return whoObject.displayName + ': ' + whoObject.objectName;
    } catch (error) {}
  }

  protected parseWhatObject(whatObject: IActivityDetails): string {
    try {
      return whatObject.displayName + ': ' + whatObject.objectName;
    } catch (error) {}
  }
}
