/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { sendRequestToFramework } from '../HelperFunctions';
import { OPERATIONS } from '../models/Operations';
import { IUserDetails } from '../models/UserDetails';

/**
 * Get details about the current user.
 */
export function getUserDetails(): Promise<IUserDetails> {
  return sendRequestToFramework(OPERATIONS.GET_USER_DETAILS, []);
}
