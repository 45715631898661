/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { INTERACTION_DIRECTION_TYPES } from './Interactions';

/**
 * Used by [[saveActivity]] and [[registerSaveActivity]] to create and update activities in CRM.
 *
 * Normally these are used to store notes and dispositions of interactions.
 * These activities should contain as much information as possible about the interaction the produced them.
 */
export interface IActivity {
  id: string;
  type: ACTIVITY_TYPE;
  subject: string;
  description: string;
  interactionDirection: INTERACTION_DIRECTION_TYPES;
  priority: ACTIVITY_PRIORITY;
  status: ACTIVITY_STATUS;
  phoneNumber?: string;
  email?: string;
  callDurationInMinutes?: number;
  callResult?: string;
  dueDate?: Date;
  relatedTo?: IRecord;
  start?: Date;
  end?: Date;
  location?: string;
  private?: boolean;
}
/**
 * Defines the type of an [[IActivity]]
 */
export enum ACTIVITY_TYPE {
  PhoneCall,
  Email,
  Appointment,
  Task,
  Default
}
/**
 * Defines the state of an [[IActivity]]
 */
export enum ACTIVITY_STATUS {
  Open,
  Closed
}
/**
 * Defines the priority of an [[IActivity]]
 */
export enum ACTIVITY_PRIORITY {
  High,
  Normal,
  Low
}

/**
 * Defines a link to an entity or record in CRM.
 * Used by [[IActivity]].
 */
export interface IRecord {
  id: string;
  type: string;
}
