import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import * as api from '@amc-technology/davinci-api';
import { LoggerService } from '../logger.service';
import { StorageService } from '../storage.service';

@Component({
  selector: 'app-search-information',
  templateUrl: './search-information-salesforce.component.html',
  styleUrls: ['./search-information-salesforce.component.css']
})
export class SearchInformationSalesforceComponent implements OnChanges {
  @Input() searchLayout: api.SearchLayouts;
  @Input() searchRecordList: Array<api.IRecordItem>;
  @Output() agentSelectedCallerInformation: EventEmitter<string>;

  constructor(private loggerService: LoggerService, public storageService: StorageService) {
    this.agentSelectedCallerInformation = new EventEmitter();
  }

  ngOnChanges() {}

  public onAgentSelectedCallerInformation(event: { id: string; value: string }) {
    this.loggerService.logger.logTrace('Salesforce - Search : START : On Agent Selected Caller Information');
    try {
      if (this.searchRecordList.length > 1) {
        this.storageService.selectedSearchRecordList[this.storageService.currentScenarioId] = event.value;
        this.agentSelectedCallerInformation.emit(event.id);
      } else {
        this.agentSelectedCallerInformation.emit(this.searchRecordList.find((i) => i.id === (this.searchRecordList.length === 1 ? event.id : event.value)).id);
      }
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Search : ERROR : On Agent Selected Caller Information. Input : ' + JSON.stringify(event) + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Salesforce - Search : END : On Agent Selected Caller Information');
  }
}
