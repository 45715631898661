/**
 * @packageDocumentation
 * @module DaVinci_API
 */
export enum NOTIFICATION_TYPE {
  Information,
  Warning,
  Error,
  Alert
}

export interface INotificationMessage {
  message: string;
  notificationType: NOTIFICATION_TYPE;
}
