import { Component, OnInit, Input } from '@angular/core';
import { IOperation } from '../../models/uilibrary.model';

/**
 * @ignore
 */
@Component({
  selector: 'amc-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.scss', './../uilibrary.module.scss']
})
export class OperationComponent implements OnInit {
  @Input() statusText: string;
  @Input() callId: string;
  @Input() callType: string;
  @Input() operations: IOperation[];

  labelledByText = '';
  describedByText = '';

  /**
   * @ignore
   */
  constructor() {
  }

  /**
   * @ignore
   */
  ngOnInit() {
    if (this.statusText && this.callType) {
      this.describedByText = `${this.statusText} ${this.callType}`;
    } else if (this.statusText) {
      this.describedByText = this.statusText;
    } else if (this.callType) {
      this.describedByText = this.callType
    }

    if (this.callId) {
      this.labelledByText = this.callId;
    }
  }

  operationKeypress(event: KeyboardEvent, operation: IOperation) {
    if (event.code === 'Enter') {
      operation.handler(operation.operationName, operation.operationMetadata);
    }
  }
}
