import { Component, Input, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'amc-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.scss', './../uilibrary.module.scss']
})
export class DurationComponent implements OnInit, OnDestroy {
  @Input() statusText: string;
  @Input() callId: string;
  @Input() startTime: number;

  displayTime: string;
  labelledByText = '';

  private _timerId?: number;

  constructor() {
    this.displayTime = '00:00/00:00';
  }

  ngOnInit() {
    if (this.statusText && this.callId) {
      this.labelledByText = `${this.statusText} ${this.callId}`;
    } else if (this.statusText) {
      this.labelledByText = this.statusText;
    } else if (this.callId) {
      this.labelledByText = this.callId
    }

    this.startCallCounter();
  }

  /**
 * @ignore
 */
  private startCallCounter(): void {
    if (this._timerId == null) {
      this._timerId = window.setInterval(() => {
        const callStartTime: number = this.startTime;
        const currentTime: number = new Date().getTime() / 1000;
        const secondsPassed: number = Math.floor((currentTime) - (callStartTime / 1000));
        this.displayTime = Math.floor(secondsPassed / 60) + ':' + ('0' + (secondsPassed % 60)).slice(-2);
      }, 1000);
    }
  }

  ngOnDestroy(): void {
    // clear the timer.
    if (this._timerId != null) {
      clearInterval(this._timerId);
      this._timerId = null;
    }
  }

}
