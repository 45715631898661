import { IDisposition, IDispositionResult } from './../../models/disposition.interface';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'amc-disposition',
  templateUrl: './disposition.component.html',
  styleUrls: ['./disposition.component.css']
})
export class DispositionComponent implements OnInit {
  @Input() disposition: IDisposition;

  @Output() dispositionEmitter = new EventEmitter<IDispositionResult>();

  selectedDispositionValue: string = '';
  dispositionName: string = '';

  constructor() { }

  ngOnInit() {
    for (const metadata of this.disposition.dispositionMetadata) {
      if (metadata.key === 'callId') {
        this.dispositionName = `disposition_${metadata.value}`;

        break;
      }
    }

    if (this.disposition.checkedDisposition) {
      this.selectedDispositionValue = this.disposition.checkedDisposition;
    }
  }

  submitDisposition() {
    const selectedDisposition: IDispositionResult = {
      dispositionId: this.selectedDispositionValue,
      dispositionName: this.disposition.disposition.get(this.selectedDispositionValue),
      dispositionMetadata: this.disposition.dispositionMetadata
    };

    this.dispositionEmitter.emit(selectedDisposition);
  }

  selectAndSubmitDisposition(event: KeyboardEvent, dispositionKey: string) {
    if (event.code === 'Enter') {
      this.selectedDispositionValue = dispositionKey;
      this.submitDisposition();
    }
  }
}
