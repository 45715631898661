export enum SF_BRIDGE_EVTS {
  GET_SEARCH_LAYOUT = 'getSearchLayout',
  UPDATE_ACTIVITY_LAYOUT = 'updateActivityLayout',
  IS_TOOLBAR_VISIBLE = 'isToolbarVisible',
  SEARCH = 'search',
  GET_ACTIVITY = 'getActivity',
  PASS_CONFIG = 'passConfig',
  SAVE_ACTIVITY = 'saveActivity',
  CREATE_NEW_ENTITY = 'createNewEntity',
  AGENT_SELECTED_CALLER_INFO = 'agentSelectedCallerInformation',
  SET_IS_IN_CONSOLE_VIEW = 'setIsInConsoleView',
  OPEN_MY_CALLS_TODAY = 'openMyCallsToday',
  SEND_LMS = 'sendLMS',
  SET_SOFTPHONE_VISIBLE = 'setSoftphoneVisibility',
  CUSTOM_SEARCH = 'customSearch',
  IS_PERSON_ACCOUNT = 'isPersonAccount',
  CLICK_TO_DIAL = 'clickToDial',
  SEND_NOTIFICATION = 'sendNotification',
  LOG_DEBUG = 'logDebug',
  LOG_TRACE = 'logTrace',
  LOG_ERROR = 'logError',
  LOG_WARNING = 'logWarning',
  LOG_INFO = 'logInformation',
  ON_FOCUS = 'onFocus',
  HVS_WORK_STARTED = 'hvsWorkStarted',
  HVS_WORK_END = 'hvsWorkEnd'
}
