import { IChatSettings } from './chat.settings';
import { IChatMessage } from './chat.message';

export * from './chat.message';
export * from './chat.settings';

/**
 * It is collection of Interaction which need to be displayed on scenario component.
 */
export interface IScenario {
  interactions: IInteraction[];
}

export interface IInteraction {
  /**
   * Unique id for the interaction.
   * Note: this interactionId is also used to provide id for the div element.
   */
  interactionId: string;
  properties?: Property[];
  associatedData?: Property[];
  startTime: number;
  displayCallTimer: boolean;
  /**
   * parties is supposed to contain data about all the participants in call except agent himself.
   */
  parties?: IParty[];
  /**
   * Operations/buttons which needs to be displayed in interaction and its handlers.
   */
  operations?: IOperation[];

  /**
   * It contains URLs for minimize, maximize, interaction specific icon urls for interaction.
   * And header number which needs to be displayed at top.
   */
  UIHeadersData: IInteractionUIHeaders;

  /**
   * Should contain identifying information about the main party in the interaction.
   */
  subheaderData: IPartyHeader;

  /**
   * This enables and configures the chat UI
   */
  chat?: {
    settings: IChatSettings;
    messages: IChatMessage[];
    isCustomerTyping?: boolean;
  };
}

export interface IActivity {
  interactionId: string;
  WhoObject: IActivityDetails;
  WhatObject: IActivityDetails;
  whoList: IActivityDetails[];
  whatList: IActivityDetails[];
  CallType: string;
  CallDurationInSeconds: string;
  Subject: string;
  SubjectFieldName: string;
  NameFieldName: string;
  RelatedToFieldName: string;
  Description: string;
  Status: string;
  ActivityDate: string;
  TimeStamp: Date;
  ActivityId: string;
  quickCommentList: string[];
}

export interface IActivityDetails  {
  objectType: string;
  displayName: string;
  objectName: string;
  objectId: string;
  url: string;
}

/**
 * This contains the main identifying information about the interaction.
 */
export interface IPartyHeader {
  /**
   * Image that identifies the type of interaction
   * or the type of the identifying information(ex: phone icon for phone number)
   */
  image?: URL;
  /**
   * The tooltip to be displayed when the image is hovered over
   */
  tooltip?: string;
  /**
   * The value to be displayed
   */
  value: string;
}

/**
 * It contains URLs for minimize, maximize, interaction specific icon urls for interaction.
 * And header number which needs to be displayed at top.
 */
export interface IInteractionUIHeaders {
  /**
   * Minimize icon url.
   */
  minimizeUrl: URL;

  /**
   * Maximize Icon url.
   */
  maximizeUrl: URL;

  /**
   * This is the URL for the status image.
   * @memberof IInteractionUIHeaders
   */
  statusUrl: URL;

  /**
   * This is the handler for focus(Triggered on click of status).
   * @memberof IInteractionUIHeaders
   */
  focusHandler?: IFocus;

  /**
   * This is the text which is going to be displayed for status of the call.
   *
   * @memberof IInteractionUIHeaders
   */
  statusText: string;

  /**
   *This member will contain the text related to the direction of the call. E.g Inbound, Outbound etc.
   * @memberof IInteractionUIHeaders
   */
  directionText: string;

  /**
   *This data will be used to display hold counter whihc will be the combination of the current hold time and complete hold time.
   * @memberof IInteractionUIHeaders
   */
  holdCounterData?: IHoldCounterData;

  /**
   *If this value is set to true then hold counter is displayed based on the holdCounterData.
   * @memberof IInteractionUIHeaders
   */
  displayHoldCounter: boolean;
}
export interface ICallDuration {
  startTime: number;
  endTime: number;
}
export interface IHoldCounterData {
  pastCallDurations?: ICallDuration[];
  currentHoldStartTime: number;
}

/**
 * It contains Operation Icon URL and its hadlers which need to be displayed in Interaction.
 */
export interface IFocus {
  /**
   * metadata related to operation.
   */
  operationMetadata?: IMetadata[];
  /**
   * name of the operation.
   */
  operationName: string;
  /**
   * Handler which needs to be invoked when someone clicks on the operation icon.
   */
  handler: (operationName: string, operationMetadata?: IMetadata[]) => void;
}

/**
 * It contains Operation Icon URL and its hadlers which need to be displayed in Interaction.
 */
export interface IOperation {
  /**
   * metadata related to operation.
   */
  operationMetadata?: IMetadata[];
  /**
   * name of the operation.
   */
  operationName: string;
  /**
   * URL for the icon of operations.
   */
  icon: URL;
  /**
   * Title which needs to be doisplayed when someone hovers over the icon.
   */
  title: string;
  /**
   * Handler which needs to be invoked when someone clicks on the operation icon.
   */
  handler: (operationName: string, operationMetadata?: IMetadata[]) => void;
  /**
   * Browser accesskey to jump instantly to this operation Should conform to AMC norms and consist of a single printable character.
   */
  accesskey?: string
}

/**
 * This contains properties and operations to be displayed for conference member.
 */
export interface IParty {
  header: IPartyHeader;
  properties: Property[];
  operations: IOperation[];
}

/**
 * key value pairs which can be used amongst other interfaces/classes to store metadata of those entities.
 */

export interface IMetadata {
  key: string;
  value: string;
}

/**
 * This interface contains key value pairs, some custom operation which generates display key and display value.
 * Also it includes custom operation handler for click event on properties.
 */
export interface IProperty {
  /**
   * KEY value to be displayed on component.
   */
  displayKey?: string;

  /**
   * VALUE to be displayed on component.
   */
  displayValue?: any;
  displayValueType: ValueType;
  propertyMetadata?: IMetadata[];

  /**
   * Click event handler for property.
   */
  customOperations?: IEventHandler;
  /**
   * This flag decides if PROPERTY will be displayed on component or not.
   */
  visible: boolean;
}

/**
 * A helper method to make instances of [IProperty]
 */
export class Property implements IProperty {
  private key: string;
  private value: any;
  /**
   * KEY value to be displayed on component.
   */
  displayKey?: string;

  /**
   * VALUE to be displayed on component.
   */
  displayValue?: any;
  displayValueType: ValueType;
  propertyMetadata?: IMetadata[];

  /**
   * Click event handler for property.
   */
  customOperations?: IEventHandler;
  /**
   * This flag decides if PROPERTY will be displayed on component or not.
   */
  visible: boolean;

  /**
   * Operation responsible for generating displayKey and dispkayValur from key and value.
   */
  displayOperation: () => void = () => {
    this.displayKey = this.key;
    this.displayValue = this.value;
  }

  /**
   *
   * @param key
   * @param value : if value type is COUNTER then value should be start time in MilliSeconds.
   * @param visible
   * @param valueType
   * @param customOperation
   */
  constructor(
    key: string,
    value: any,
    visible?: boolean,
    valueType?: ValueType,
    customOperation?: IEventHandler
  ) {
    this.key = key;
    this.value = value;
    visible != null ? (this.visible = visible) : (this.visible = true);
    valueType != null
      ? (this.displayValueType = valueType)
      : (this.displayValueType = ValueType.STRING);
    this.customOperations = customOperation;
    this.displayOperation();
  }
}

/**
 * Interface which contains eventName and handler associated with it.
 */
export interface IEventHandler {
  eventName: string;
  handler: (eventName: string, eventMetadata?: IMetadata[]) => void;
  eventMetadata?: IMetadata[];
}

/**
 * enum for Value types of PROPERTY.
 */
export enum ValueType {
  STRING,
  COUNTER
}
