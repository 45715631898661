import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ERROR_CODE, LOG_LEVEL, SearchLayouts } from '@amc-technology/davinci-api';

import { IActivity } from '../Model/Interfaces/IActivity';
import { IActivityDetails } from '../Model/Interfaces/IActivityDetails';
import { LoggerService } from '../logger.service';
import { StorageService } from '../storage.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-activity',
  templateUrl: './activity-salesforce.component.html',
  styleUrls: ['./activity-salesforce.component.css']
})
export class ActivitySalesforceComponent implements OnInit {
  log: (logLevel: LOG_LEVEL, fName: string, message: string, object?: any, errorCode?: ERROR_CODE, localTime?: Date) => void;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('clickSubject') clickSubject: Subject<any>;
  @Input() searchLayout: SearchLayouts;
  @Input() tabbedActivityList: any[];
  @Input() scenarioId: string;
  @Input() quickCommentList: string[];
  @Input() enableAutoSave: boolean;
  @Input() enableDiscard: boolean;
  @Input() autoSaveTimer: number;
  @Input() quickCommentOptionRequiredCadArray: any;
  @Output() saveActivity: EventEmitter<string> = new EventEmitter<string>();
  @Output() discardActivity: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('activityTabset') activityTabset;
  @Output() agentSelectedCallerInformation: EventEmitter<string>;

  private discardTimer;
  isActivityMaximized: boolean;

  constructor(private loggerService: LoggerService, public storageService: StorageService) {
    this.log = this.loggerService.log;
    this.isActivityMaximized = true;
    this.agentSelectedCallerInformation = new EventEmitter();
  }

  async ngOnInit() {
    try {
      this.clickSubject.subscribe((scenarioId) => {
        this.switchNgBTab(scenarioId);
        this.activityTabset.activeId = scenarioId;
      });
      if (this.enableAutoSave && this.enableDiscard) {
        if (this.storageService.currentTimer === undefined || this.storageService.currentTimer <= 0) {
          if (this.storageService.getPreviousTimer() > 0) {
            this.storageService.setCurrentTimer(this.scenarioId, this.storageService.getPreviousTimer());
          } else {
            this.storageService.setCurrentTimer(this.scenarioId, this.autoSaveTimer);
          }
        }

        this.discardTimer = setInterval(() => {
          this.storageService.setCurrentTimer(this.scenarioId, this.storageService.currentTimer - 1);
          if (this.storageService.currentTimer <= 0) {
            clearInterval(this.discardTimer);
          }
        }, 1000);
      }
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Activity : ERROR : Initialize Activity. Scenario ID : ' + this.scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  switchNgBTab(id: string) {
    try {
      this.activityTabset.select(id);
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Activity : ERROR : Switch tab. Activity Tab ID : ' + id + '. Error Information : ' + JSON.stringify(error));
    }
  }

  getEvent(event: { eventName: string; newValue?: IActivity }) {
    const eventList = event.eventName.split('|');
    for (const eventName of eventList) {
      switch (eventName) {
        case 'TriggerDiscardActivity':
          this.triggerDiscardActivity(event.newValue.ScenarioId);
          break;
        case 'ActivitySubjectChanged':
          this.onSubjectChange(event.newValue);
          break;
        case 'ActivityWhoObjectChanged':
          this.onNameChange(event.newValue);
          break;
        case 'ActivityWhatObjectChanged':
          this.onRelatedToChange(event.newValue);
          break;
        case 'ActivityCallNoteChanged':
          this.onCallNotesChange(event.newValue);
          break;
        case 'SubmitActivity':
          this.submitActivity(event.newValue);
          break;
        default:
      }
    }
  }

  onAgentSelectedCallerInformation(event: any) {
    try {
      this.agentSelectedCallerInformation.emit(event);
    } catch (error) {}
  }

  protected submitActivity(data: IActivity) {
    this.loggerService.logger.logTrace('Salesforce - Activity : START : Submit Activity. Scenario ID : ' + data);
    try {
      this.storageService.activityList[data.ScenarioId].IsProcessing = true;
      this.saveActivity.emit(data.ScenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Activity : ERROR : Submit Activity. Scenario ID : ' + data + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Salesforce - Activity : END : Submit Activity. Scenario ID : ' + data);
  }

  protected triggerDiscardActivity(scenarioId: string) {
    this.loggerService.logger.logTrace('Salesforce - Activity : START : Discard Activity. Scenario ID : ' + scenarioId);
    try {
      this.storageService.activityList[scenarioId].IsProcessing = true;
      this.discardActivity.emit(scenarioId);
      if (this.enableAutoSave && this.enableDiscard) {
        clearInterval(this.discardTimer);
        this.storageService.setCurrentTimer(this.scenarioId, this.autoSaveTimer);
      }
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Activity : ERROR : Discard Activity. Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Salesforce - Activity : END : Discard Activity. Scenario ID : ' + scenarioId);
  }

  protected isChangesUnSaved(scenarioId: string): boolean {
    const activity = this.storageService.getActivity();
    this.storageService.activityList[scenarioId].IsUnSaved = activity.IsUnSaved || (!activity.ActivityId && !this.enableAutoSave);
    return this.storageService.activityList[scenarioId].IsUnSaved;
  }

  protected onNameChange(data: IActivity) {
    this.loggerService.logger.logTrace('Salesforce - Activity : START : On Name Change. Input : ' + JSON.stringify(data));
    try {
      this.storageService.selectedWhoValueList[data.ScenarioId] = data.WhoObject.objectId;
      this.storageService.UpdateWhoObjectSelectionChange(data.WhoObject.objectId, data.ScenarioId);
      this.storageService.compareActivityFields(data.ScenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Activity : ERROR : On Name Change. Input : ' + JSON.stringify(data) + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Salesforce - Activity : END : On Name Change. Input : ' + JSON.stringify(data));
  }

  protected onRelatedToChange(data: IActivity) {
    this.loggerService.logger.logTrace('Salesforce - Activity : START : On Related To Change. Input : ' + JSON.stringify(data));
    try {
      this.storageService.selectedWhatValueList[data.ScenarioId] = data.WhatObject.objectId;
      this.storageService.UpdateWhatObjectSelectionChange(data.WhatObject.objectId, this.scenarioId);
      this.storageService.compareActivityFields(this.scenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Activity : ERROR : On Related To Change. Input : ' + JSON.stringify(data) + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Salesforce - Activity : END : On Related To Change. Input : ' + JSON.stringify(data));
  }

  protected onSubjectChange(data: IActivity) {
    this.loggerService.logger.logTrace('Salesforce - Activity : START : On Subject Change. Input : ' + JSON.stringify(data));
    try {
      this.storageService.activityList[data.ScenarioId].IsSubjectChanged = true;
      this.storageService.setSubject(data.Subject, data.ScenarioId);
      this.storageService.compareActivityFields(data.ScenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Activity : ERROR : On Subject Change. Input : ' + JSON.stringify(data) + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Salesforce - Activity : END : On Subject Change. Input : ' + JSON.stringify(data));
  }

  protected onCallNotesChange(data: IActivity) {
    this.loggerService.logger.logTrace('Salesforce - Activity : START : On Call Notes Change. Input : ' + JSON.stringify(data));
    try {
      this.storageService.setDescription(data.Description, this.scenarioId);
      this.storageService.compareActivityFields(this.scenarioId);
      this.submitActivity(data);
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Activity : ERROR : On Call Notes Change. Input : ' + JSON.stringify(data) + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Salesforce - Activity : END : On Call Notes Change. Input : ' + JSON.stringify(data));
  }

  protected loadQuickComment(comment: string) {
    this.loggerService.logger.logTrace('Salesforce - Activity : START : Load Quick Comments. Input : ' + comment);
    try {
      let descriptionToSet = this.quickCommentList[comment];
      if (this.quickCommentOptionRequiredCadArray[comment]) {
        let cadFields = {};
        if (this.storageService.activityList[this.scenarioId]) {
          cadFields = this.storageService.scenarioToCADMap[this.scenarioId];
        }
        for (let i = 0; i < this.quickCommentOptionRequiredCadArray[comment].length; i++) {
          let keyToCheckIfCADExists = this.quickCommentOptionRequiredCadArray[comment][i];
          const stringToBeReplaced = this.quickCommentOptionRequiredCadArray[comment][i];
          keyToCheckIfCADExists = keyToCheckIfCADExists.replace('{{', '');
          keyToCheckIfCADExists = keyToCheckIfCADExists.replace('}}', '');
          if (cadFields[keyToCheckIfCADExists]) {
            descriptionToSet = descriptionToSet.replace(stringToBeReplaced, cadFields[keyToCheckIfCADExists].Value);
          }
        }
      }
      if (!this.storageService.getDescription()) {
        this.storageService.setDescription(descriptionToSet, this.scenarioId);
      } else {
        this.storageService.setDescription(this.storageService.getDescription() + '\n' + descriptionToSet, this.scenarioId);
      }
      this.storageService.compareActivityFields(this.scenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Activity : ERROR : Load Quick Comments. Input : ' + comment + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Salesforce - Activity : END : Load Quick Comments. Input : ' + comment);
  }

  protected parseWhoObject(whoObject: IActivityDetails): string {
    this.loggerService.logger.logTrace('Salesforce - Activity : Parsing Who Object. Input : ' + JSON.stringify(whoObject));
    try {
      return whoObject.displayName + ': ' + whoObject.objectName;
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Activity : ERROR : Parsing Who Object. Input : ' + JSON.stringify(whoObject) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  protected parseWhatObject(whatObject: IActivityDetails): string {
    this.loggerService.logger.logTrace('Salesforce - Activity : Parsing What Object. Input : ' + JSON.stringify(whatObject));
    try {
      return whatObject.displayName + ': ' + whatObject.objectName;
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Activity : ERROR : Parsing What Object. Input : ' + JSON.stringify(whatObject) + '. Error Information : ' + JSON.stringify(error));
    }
  }
}
