import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewChecked, AfterViewInit, OnInit, Renderer2 } from '@angular/core';
import { IChatMessage, IChatMessageType } from '../../models/chat.message';
import { IChatSettings } from '../../models/chat.settings';

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss'],
})
export class ChatBoxComponent implements AfterViewChecked, AfterViewInit, OnInit {
  @Input() settings: IChatSettings;
  @Input() isTyping = false;

  @Input() messages: IChatMessage[];
  @Input() newMsgs: IChatMessage[];
  private lastCount = -1;

  _isAgentTyping = false;
  @Output() isAgentTyping = new EventEmitter<boolean>();

  @Output() newMessage = new EventEmitter<string>();
  newMessageText = '';

  @ViewChild('MessagesContainer', { static: true }) messagesContainer: ElementRef;
  @ViewChild('autofocus', { static: false }) autofocus: ElementRef;

  usernameToColor: { [username: string]: string } = {};
  colors = [
    '#c2c4c4',
    '#ffab91',
    '#f48fb1',
    '#29b6f6',
    '#e1bee7',
    '#9ccc65',
    '#ffc107',
    '#26c6da',
    '#cddc39',
  ];
  colorsIndex = 0;
  agentColor = '#2db0e0';

  constructor(private renderer:Renderer2) {
  }
    ngOnInit(): void {
      this.renderer.selectRootElement('#autofocus').focus();
  }

 ngAfterViewInit(): void {
    this.renderer.selectRootElement('#autofocus').focus();
  }

  updatedMessage(): IChatMessage[]  {
    try {
      if (typeof this.newMsgs !== 'undefined' && this.newMsgs.length > 0) {
        return this.newMsgs;
      } else {
        return this.messages;
      }
    }catch (e) { }
    finally {
      this.scrollToBottom();
    }

  }

  ngAfterViewChecked() {
    if (this.lastCount !== this.messages.length) {
      this.lastCount = this.messages.length;
      this.scrollToBottom();

    }
  }

  getColor(message: IChatMessage) {
    if (message.type && message.type === IChatMessageType.AGENT) {
      return this.agentColor;
    } else if (message.username) {
      if (!this.usernameToColor[message.username]) {
        this.usernameToColor[message.username] = this.colors[this.colorsIndex];
        this.colorsIndex = (this.colorsIndex + 1) % this.colors.length;
      }
      return this.usernameToColor[message.username];
    } else {
      return this.colors[0];
    }
  }

  shouldAlignRight(message: IChatMessage) {
    return message.type && message.type === IChatMessageType.AGENT;
  }

  sendNewMessage(event) {
    event.preventDefault();
    if (this.newMessageText) {
      this.newMessage.emit(this.newMessageText);
      this.newMessageText = '';
    }
    this.renderer.selectRootElement('#autofocus').focus();
  }

  setOnFocus(event)  {
    this.renderer.selectRootElement('#autofocus').focus();
  }

  onNewMessageFocus(event: boolean) {
    const isTyping = event && this.newMessageText.length > 0;
    if (isTyping !== this._isAgentTyping) {
      this._isAgentTyping = isTyping;
      this.isAgentTyping.emit(isTyping);
    }
  }

  onKeyup() {
    const isTyping = this.newMessageText.length > 0;
    if (isTyping !== this._isAgentTyping) {
      this._isAgentTyping = isTyping;
      this.isAgentTyping.emit(isTyping);
    }
  }

  scrollToBottom() {
    try {
      this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
    }
    catch (e) { }
  }
}
