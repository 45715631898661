import { IHvsNewWorkItem } from '../Interfaces/IOpenCtiSdk';

export class DaVinciHvsWorkItem {
  newWorkItem: IHvsNewWorkItem;
  scenarioId?: string;
  wasConnected: boolean;

  constructor(newWorkItem?: IHvsNewWorkItem, scenarioId?: string, wasConnected?: boolean) {
    this.newWorkItem = newWorkItem;
    this.scenarioId = scenarioId;
    this.wasConnected = wasConnected || false; // Obviously this is almost redundant. Just ensuring it is only true or false, not undefined or null.
  }
}
