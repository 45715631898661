import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent implements OnInit {
  @Input() message: string;
  @Input() alignRight = false;
  @Input() username: string;
  @Input() color: string;
  @Input() timestamp: string;
  @Input() image: string;
  @Input() fallbackImage: string;
  @Output() focusOn = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this.focusOn.emit(true);
  }


}
