import { Component, AfterViewChecked, ElementRef } from '@angular/core';
import * as applicationAPI from '@amc-technology/davinci-api';
@Component({
  selector: 'app-root',
  templateUrl: './salesforce.component.html',
  styleUrls: ['./salesforce.component.css']
})
export class SalesforceComponent implements AfterViewChecked {
  height: number;
  heightNonZero: boolean;
  el: ElementRef;
  constructor(el: ElementRef) {
    this.height = 500;
    this.heightNonZero = false;
    this.el = el;
  }

  ngAfterViewChecked(): void {
    this.setHeight();
  }

  private setHeight(): void {
    const newHeight = this.getHeight();
    // If the height has ever been nonzero we cannot set it to zero again
    if (this.heightNonZero && newHeight !== 2) {
      if (newHeight !== this.height) {
        this.height = newHeight;
        applicationAPI.setAppHeight(this.height);
      }
    }
  }

  private getHeight(): number {
    if (this.el.nativeElement.children[0].scrollHeight !== 0) {
      this.heightNonZero = true;
    }
    return this.el.nativeElement.children[0].scrollHeight + 2;
  }
}
