/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { sendRequestToFramework } from '../HelperFunctions';
import { OPERATIONS } from '../models/Operations';

/**
 * An app can use this to load javascript files on the CCC iframe.
 * This is useful if the app needs access to the parent iframe to interact with the CRM.
 * @param javascriptUrls Urls of js files to load
 */
export function loadBridgeScripts(javascriptUrls: string[]): Promise<void> {
  return sendRequestToFramework(OPERATIONS.LOAD_SCRIPT, [javascriptUrls]).then(
    () => {} // make sure we return void
  );
}
