/**
 * @packageDocumentation
 * @module DaVinci_API
 */

/**
 * Defines the type of channel that produced an event.
 */
export enum CHANNEL_TYPES {
  Telephony,
  Email,
  Lead,
  Chat,
  Contact,
  Prospect,
  Account,
  Social,
  SMS
}
