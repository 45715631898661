/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { IRecordItem } from './RecordItem';

/**
 * A collection of [[RecordItem]]s
 */
export class SearchRecords {
  private records: IRecordItem[];
  constructor() {
    this.records = [];
  }

  static fromJSON(input: IRecordItem[]) {
    const result = new SearchRecords();
    result.records = input;
    return result;
  }

  toJSON() {
    return this.records;
  }

  addSearchRecord(record: IRecordItem) {
    this.records.push(record);
  }
}
