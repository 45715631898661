export function secondsToHms(seconds_in: number): string {
  seconds_in = Number(seconds_in);
  const h = Math.floor(seconds_in / 3600);
  const m = Math.floor(seconds_in % 3600 / 60);
  const s = Math.floor(seconds_in % 3600 % 60);

  const hDisplay = h > 0 ? h.toString() + ':' : '';
  const mDisplay = m > 0 ? ('0' + m.toString()).slice(-2) + ':' : '00:';
  const sDisplay = s > 0 ? ('0' + s.toString()).slice(-2) : '00';
  return hDisplay + mDisplay + sDisplay;
}
