import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { IChatMessage, IInteraction } from '../../models/uilibrary.model';

/**
 * @ignore
 */
@Component({
  selector: 'amc-interaction',
  templateUrl: './interaction.component.html',
  styleUrls: ['./interaction.component.scss', './../uilibrary.module.scss']
})
export class InteractionComponent implements OnInit {

  @Input() interaction: IInteraction;
  @Input() newMsgs: IChatMessage[];
  @Output() minimizedChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() isAgentTyping = new EventEmitter<boolean>();
  @Output() newMessage = new EventEmitter<string>();
   /**
   * @ignore
   */
  minimized: boolean;
  set _minimized(value) {
    this.minimized = value;
    this.minimizedChanged.emit(value);
  }
  get _minimized() {
    return this.minimized;
  }

  /**
   * @ignore
   */
  constructor() {
    this._minimized = false;
  }

  /**
   * @ignore
   */
  ngOnInit() {
  }

  /**
   * @ignore
   */
  minimize(): void {
    this._minimized = true;
  }

  /**
   * @ignore
   */
  maximize(): void {
    this._minimized = false;
  }

  /**
   * @ignore
   */
  isConferenceCall(): boolean {
    if (this.interaction.parties && this.interaction.parties.length > 1) {
      return true;
    }
    return false;
  }

  collapseKeypress(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      this.minimize();
    }
  }

  expandKeypress(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      this.maximize();
    }
  }

  clickEvent(id){
    document.getElementById(id).className = 'success';
    if(localStorage.getItem('focusedPanel') === null) {
      localStorage.setItem('focusedPanel', id);
    } else if (localStorage.getItem('focusedPanel') != id){
      document.getElementById(localStorage.getItem('focusedPanel')).className = 'AnswerCallFocused';
      localStorage.setItem('focusedPanel', id);
    }
  }



}
