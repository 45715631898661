/**
 * @packageDocumentation
 * @module DaVinci_API
 */

import { IField } from './Field';
import { ISearchLayoutForEntity } from './SearchLayout';

/**
 * Defines how to search a specific record type in the CRM.
 */

export class SearchLayoutForEntity implements ISearchLayoutForEntity {
  DisplayName: string;
  DevName: string;
  DisplayFields: IField[];
  PhoneFields: IField[];
  EmailFields: IField[];
  SocialFields: IField[];
  NameFields: IField[];
  constructor(
    displayName: string,
    devName: string,
    displayFields?: IField[],
    phoneFields?: IField[],
    emailFields?: IField[],
    socialFields?: IField[],
    nameFields?: IField[]
  ) {
    this.DisplayName = displayName;
    this.DevName = devName;
    this.DisplayFields = displayFields || [];
    this.PhoneFields = phoneFields || [];
    this.EmailFields = emailFields || [];
    this.SocialFields = socialFields || [];
    this.NameFields = nameFields || [];
  }

  static fromJSON(input: ISearchLayoutForEntity) {
    return new SearchLayoutForEntity(
      input.DisplayName,
      input.DevName,
      input.DisplayFields,
      input.PhoneFields,
      input.EmailFields,
      input.SocialFields,
      input.NameFields
    );
  }

  getDisplayName() {
    return this.DisplayName;
  }

  setDisplayName(name: string) {
    this.DisplayName = name;
  }

  getDevName() {
    return this.DevName;
  }

  setDevName(name: string) {
    this.DevName = name;
  }

  getDisplayFields() {
    return this.DisplayFields;
  }

  setDisplayFields(fields: IField[]) {
    this.DisplayFields = fields;
  }

  getPhoneFields() {
    return this.PhoneFields;
  }

  setPhoneFields(fields: IField[]) {
    this.PhoneFields = fields;
  }

  getEmailFields() {
    return this.EmailFields;
  }

  setEmailFields(fields: IField[]) {
    this.EmailFields = fields;
  }

  getSocialFields() {
    return this.SocialFields;
  }

  setSocialFields(fields: IField[]) {
    this.SocialFields = fields;
  }

  getNameFields() {
    return this.NameFields;
  }

  setNameFields(fields: IField[]) {
    this.NameFields = fields;
  }
}
