import {
  Component,
  Input,
  Output,
  EventEmitter,
   AfterViewChecked
} from '@angular/core';
import { IChatMessage, IScenario } from '../../models/uilibrary.model';

@Component({
  selector: 'amc-scenario',
  templateUrl: './scenario.component.html',
  styleUrls: ['./scenario.component.scss', './../uilibrary.module.scss']
})
export class ScenarioComponent implements AfterViewChecked {
  @Input() scenario: IScenario;
  @Input() newMsgs: IChatMessage[];
  @Output() minimizedChanged = new EventEmitter();
  @Output() isAgentTyping = new EventEmitter<boolean>();
  @Output() newMessage = new EventEmitter<string>();
  @Output() afterViewChecked = new EventEmitter();

    constructor() {}

  ngAfterViewChecked() {
    this.afterViewChecked.emit();
  }

  onMinimizedChanged() {
    this.minimizedChanged.emit();
  }
}
