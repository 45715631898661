import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnChanges,
  OnDestroy
} from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IActivity } from '../../models/IActivity';
import { IActivityDetails } from '../../models/IActivityDetails';

@Component({
  selector: 'amc-recent-activity',
  templateUrl: './recent-activity.component.html',
  styleUrls: ['./recent-activity.component.css']
})
export class RecentActivityComponent implements OnInit, OnChanges, OnDestroy {
  @Input() ActivityDetails: IActivity;
  @Input() isInConsoleView: boolean;
  @Input() quickCommentList: string[];
  @Input() workingScenarioID: string;
  @Input() idx: number;
  @Input() last: boolean;
  @Input() WhoObjectList: IActivityDetails[];
  @Input() WhatObjectList: IActivityDetails[];

  @Output() EventEmitter: EventEmitter<{
    eventName: string;
    idx: number;
    newValue?: IActivity;
  }>;

  private debouncer: Subject<void>;
  private eventList: Set<String>;

  constructor() {
    this.EventEmitter = new EventEmitter<{
      eventName: string;
      idx: number;
      newValue?: IActivity;
    }>();

    this.debouncer = new Subject();
    this.eventList = new Set();
  }

  ngOnChanges(): void {
  }

  getIcon(scenario): string {
    switch (scenario) {
      case 'Telephony':
        return '../../assets/images/Phone_Number_Icon.png';
      case 'Email':
        return '../../assets/images/emailTab.png';
      default:
        return '../../assets/images/chat.png';
    }
  }

  ngOnInit(): void {
    this.debouncer.pipe(debounceTime(500)).subscribe(() => {
      const newEvent = {
        eventName: Array.from(this.eventList).join('|'),
        idx: this.idx,
        newValue: this.ActivityDetails
      };
      this.EventEmitter.emit(newEvent);
      this.eventList.clear();
    });
  }

  ngOnDestroy(): void {}

  expandAndCollapseRecentActivity(isExpand: boolean) {
    if (isExpand) {
      this.EventEmitter.emit({
        eventName: 'WorkingEvtivityChanged',
        idx: this.idx,
        newValue: this.ActivityDetails
      });
    } else {
      this.EventEmitter.emit({
        eventName: 'WorkingEvtivityChanged',
        idx: -1,
        newValue: this.ActivityDetails
      });
    }
  }

  openActivity() {
    this.EventEmitter.emit({
      eventName: 'OpenCallActivity',
      idx: this.idx,
      newValue: this.ActivityDetails
    });
  }

  onSubjectChange() {
    this.eventList.add('ActivitySubjectChanged');
    this.debouncer.next();
  }

  onSubjectKeyUp() {
    this.eventList.add('ActivitySubjectChanged');
    this.debouncer.next();
  }

  onWhoObjectChange(event: any) {
    this.ActivityDetails.WhoObject = this.WhoObjectList.find((obj) => obj.objectId === event);
    this.EventEmitter.emit({
      eventName: 'ActivityWhoObjectChanged',
      idx: this.idx,
      newValue: this.ActivityDetails
    });
  }

  onRelatedToChange(event: any) {
    this.ActivityDetails.WhatObject = this.WhatObjectList.find((obj) => obj.objectId === event);
    this.EventEmitter.emit({
      eventName: 'ActivityWhatObjectChanged',
      idx: this.idx,
      newValue: this.ActivityDetails
    });
  }

  onCallNotesChange() {
    this.eventList.add('ActivityCallNoteChanged');
    this.debouncer.next();
  }

  onCallNotesKeyUp() {
    this.eventList.add('ActivityCallNoteChanged');
    this.debouncer.next();
  }

  addQuickCommentToDescription(comment: string) {
    if (this.ActivityDetails.Description) {
      this.ActivityDetails.Description += '\n';
    }
    this.ActivityDetails.Description += comment;
    this.eventList.add('ActivityCallNoteChanged');
    this.debouncer.next();
  }

  submitActivity() {
    this.ActivityDetails.IsProcessing = true;
    this.EventEmitter.emit({
      eventName: 'SubmitActivity',
      idx: this.idx,
      newValue: this.ActivityDetails
    });
  }
}
