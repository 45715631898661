/**
 * @packageDocumentation
 * @module DaVinci_API
 */

import { CHANNEL_TYPES } from './ChannelTypes';
import { ISearchLayout } from './SearchLayout';

/**
 * Defines how to search and pop records in the CRM.
 *
 * A collection of [[SearchLayout]]
 */

export class SearchLayouts {
  layouts: { [channel: number]: ISearchLayout };

  static fromJSON(layouts: { [channel: number]: ISearchLayout }) {
    return new SearchLayouts(layouts);
  }

  constructor(layouts?: { [channel: number]: ISearchLayout }) {
    this.layouts = layouts || {};
  }

  toJSON() {
    return this.layouts;
  }

  setLayout(channels: CHANNEL_TYPES[], layout: ISearchLayout) {
    channels.forEach((channel) => {
      this.layouts[channel] = layout;
    });
  }

  getLayout(channel: CHANNEL_TYPES) {
    return this.layouts[channel];
  }

  getAllLayouts() {
    return this.layouts;
  }
}
