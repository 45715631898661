/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { IField } from './Field';

/**
 * Underlies [[SearchLayout]]
 */
export interface ISearchLayout {
  OpenInNewWindow: boolean;
  NoMatch: { type: NO_MATCH_POP_TYPES; data?: any };
  SingleMatch: { type: SINGLE_MATCH_POP_TYPES; data?: any };
  MultiMatch: { type: MULTI_MATCH_POP_TYPES; data?: any };
  Default: ISearchLayoutForEntity[];
  Inbound: ISearchLayoutForEntity[];
  Outbound: ISearchLayoutForEntity[];
  Internal: ISearchLayoutForEntity[];
}
/**
 * Underlies [[SearchLayoutForEntity]]
 */
export interface ISearchLayoutForEntity {
  DisplayName: string;
  DevName: string;
  DisplayFields: IField[];
  PhoneFields: IField[];
  EmailFields: IField[];
  SocialFields: IField[];
  NameFields: IField[];
}

/**
 * What to do pop on a no match
 */
export enum NO_MATCH_POP_TYPES {
  NoPop,
  PopToNewEntity,
  PopToUrl
}
/**
 * What to do pop on a multi match
 */
export enum MULTI_MATCH_POP_TYPES {
  NoPop,
  PopToSearch,
  PopToUrl
}
/**
 * What to do pop on a single match
 */
export enum SINGLE_MATCH_POP_TYPES {
  NoPop,
  PopToDetials,
  PopToUrl
}
/**
 * Defines how to search and pop records in the CRM.
 */
export class SearchLayout implements ISearchLayout {
  OpenInNewWindow: boolean;
  NoMatch: { type: NO_MATCH_POP_TYPES; data?: any };
  SingleMatch: { type: SINGLE_MATCH_POP_TYPES; data?: any };
  MultiMatch: { type: MULTI_MATCH_POP_TYPES; data?: any };
  Default: ISearchLayoutForEntity[];
  Inbound: ISearchLayoutForEntity[];
  Outbound: ISearchLayoutForEntity[];
  Internal: ISearchLayoutForEntity[];
  constructor(openInWindow: boolean, defaultLayout: ISearchLayoutForEntity[]) {
    this.OpenInNewWindow = openInWindow;
    this.Default = defaultLayout;
    this.NoMatch = { type: NO_MATCH_POP_TYPES.NoPop };
    this.SingleMatch = { type: SINGLE_MATCH_POP_TYPES.NoPop };
    this.MultiMatch = { type: MULTI_MATCH_POP_TYPES.NoPop };
    this.Inbound = [];
    this.Outbound = [];
    this.Internal = [];
  }

  static fromJSON(input: ISearchLayout) {
    const result = new SearchLayout(input.OpenInNewWindow, input.Default);
    result.setNoMatch(input.NoMatch);
    result.setSingleMatch(input.SingleMatch);
    result.setMultiMatch(input.MultiMatch);
    result.setInbound(input.Inbound);
    result.setOutbound(input.Outbound);
    result.setInternal(input.Internal);
    return result;
  }

  getOpenInNewWindow() {
    return this.OpenInNewWindow;
  }

  setOpenInNewWindow(openInNewWindow: boolean) {
    this.OpenInNewWindow = openInNewWindow;
  }

  getNoMatch() {
    return this.NoMatch;
  }

  setNoMatch(noMatch: { type: NO_MATCH_POP_TYPES; data?: any }) {
    this.NoMatch = noMatch;
  }

  getSingleMatch() {
    return this.SingleMatch;
  }

  setSingleMatch(singleMatch: { type: SINGLE_MATCH_POP_TYPES; data?: any }) {
    this.SingleMatch = singleMatch;
  }

  getMultiMatch() {
    return this.MultiMatch;
  }

  setMultiMatch(multiMatch: { type: MULTI_MATCH_POP_TYPES; data?: any }) {
    this.MultiMatch = multiMatch;
  }

  getDefault() {
    return this.Default;
  }

  setDefault(defaultLayout: ISearchLayoutForEntity[]) {
    this.Default = defaultLayout;
  }

  getInbound() {
    return this.Inbound;
  }

  setInbound(inbound: ISearchLayoutForEntity[]) {
    this.Inbound = inbound;
  }

  getOutbound() {
    return this.Outbound;
  }

  setOutbound(outbound: ISearchLayoutForEntity[]) {
    this.Outbound = outbound;
  }

  getInternal() {
    return this.Internal;
  }

  setInternal(internal: ISearchLayoutForEntity[]) {
    this.Internal = internal;
  }
}
