import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IActivity, IActivityDetails } from '../../models/uilibrary.model';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {

isActivityMaximized: boolean;
@Input() activity: IActivity;
@Output() ActivitySave: EventEmitter<IActivity> = new EventEmitter<IActivity>();
@Output() OnNameSelectChange: EventEmitter<IActivity> = new EventEmitter<IActivity>();
@Output() OnRelatedToChange: EventEmitter<IActivity> = new EventEmitter<IActivity>();
@Output() OnSubjectChange: EventEmitter<IActivity> = new EventEmitter<IActivity>();
@Output() OnCallNotesChange: EventEmitter<IActivity> = new EventEmitter<IActivity>();

  constructor() {
    this.isActivityMaximized = true;
  }

  ngOnInit() {
  }

  public onNameSelectChange(event) {
    this.activity.WhoObject = this.getWho(event.currentTarget.value);
    this.OnNameSelectChange.emit(this.activity);
  }

  public parseWhoObject(whoObject: IActivityDetails): string {
    return whoObject.objectType + ': ' + whoObject.objectName;
  }

  public parseWhatObject(whatObject: IActivityDetails): string {
    return whatObject.objectType + ': ' + whatObject.objectName;
  }

  public onRelatedToChange(event) {
    this.activity.WhatObject = this.getWhat(event.currentTarget.value);
    this.OnRelatedToChange.emit(this.activity);
  }

  public onSubjectChange(event) {
    this.activity.Subject = event.srcElement.value;
    this.OnSubjectChange.emit(this.activity);
  }
  public onCallNotesChange(event) {
    this.activity.Subject = event.srcElement.value.trim();
    this.OnCallNotesChange.emit(this.activity);
  }

  public getWho(id): IActivityDetails {
    for (let i = 0; i < this.activity.whoList.length; i++) {
      if (this.activity.whoList[i].objectId === id) {
        return this.activity.whoList[i];
      }
    }
  }
  public getWhat(id): IActivityDetails {
    for (let i = 0; i < this.activity.whatList.length; i++) {
      if (this.activity.whatList[i].objectId === id) {
        return this.activity.whatList[i];
      }
    }
  }

  public loadQuickComment(value) {
    this.activity.Description = this.activity.Description + this.activity.quickCommentList[value];
  }

  public activitySave(clearActivityFields) {
    this.ActivitySave.emit(this.activity);
  }
}
