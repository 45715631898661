export class ModifiedNumber {
  private _originalNumber: string;
  private _updatedNumber: string;
  private _isNew: boolean;
  private _suffix: { key: string; val: string };
  private _prefix: { key: string; val: string };
  private _scenarioID: string;
  private _suffixCadName: string;
  private _prefixCadName: string;

  constructor(number: string = '') {
    this._originalNumber = number;
    this.updatedNumber = '';
    this._isNew = true;
    this._scenarioID = '';
    this._suffix = undefined;
    this._prefix = undefined;
  }

  public get originalNumber() {
    return this._originalNumber;
  }

  public set originalNumber(value: string) {
    this._originalNumber = value;
  }

  public get suffix() {
    return this._suffix;
  }

  public set suffix(value: { key: string; val: string }) {
    this._suffix = value;
  }

  public get prefix() {
    return this._prefix;
  }

  public set prefix(value: { key: string; val: string }) {
    this._prefix = value;
  }

  public get isNew(): boolean {
    return this._isNew;
  }

  public set isNew(val: boolean) {
    this._isNew = val;
  }

  public get scenarioID(): string {
    return this._scenarioID;
  }

  public set scenarioID(id: string) {
    this._scenarioID = id;
  }

  public get updatedNumber(): string {
    return this._updatedNumber;
  }

  public set updatedNumber(number: string) {
    this._updatedNumber = number;
  }

  public get suffixCadName() {
    return this._suffixCadName;
  }

  public set suffixCadName(value: string) {
    this._suffixCadName = value;
  }

  public get prefixCadName() {
    return this._prefixCadName;
  }

  public set prefixCadName(value: string) {
    this._prefixCadName = value;
  }
}
