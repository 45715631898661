export interface IChatMessage {
  username?: string;
  text: string;
  /**
   * What type of chat message. Defaults to OTHER_PERSON
   */
  type?: IChatMessageType;
  timestamp?: string;
  userIcon?: URL;
}

export enum IChatMessageType {
  AGENT = 'AGENT',
  OTHER_PERSON = 'OTHER_PERSON',
  INFORMATION = 'INFORMATION'
}
