import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IHoldCounterData, ICallDuration } from '../../models/uilibrary.model';
import { secondsToHms } from '../../Helpers';

@Component({
  selector: 'amc-holdtimer',
  templateUrl: './holdtimer.component.html',
  styleUrls: ['./holdtimer.component.scss', './../uilibrary.module.scss']
})
export class HoldtimerComponent implements OnInit, OnDestroy {
  @Input() holdCounterData: IHoldCounterData;
  @Input() statusText: string;
  @Input() callId: string;

  displayTime: string;
  labelledByText = '';

  private _timerId?: number;
  constructor() {
    this.displayTime = '00:00/00:00';
  }

  ngOnInit() {
    if (this.statusText && this.callId) {
      this.labelledByText = `${this.statusText} ${this.callId}`;
    } else if (this.statusText) {
      this.labelledByText = this.statusText;
    } else if (this.callId) {
      this.labelledByText = this.callId
    }

    this.startCallCounter();
  }
  /**
   * @ignore
   */
  private startCallCounter(): void {
    const pastTimeInSeconds = this.CaculatePastHoldDuration();
    if (this._timerId == null) {
      this._timerId = window.setInterval(() => {
        const currentTime: number = new Date().getTime() / 1000;
        const secondsPassedCurrentCall: number = Math.floor((currentTime) - (this.holdCounterData.currentHoldStartTime / 1000));
        this.displayTime = secondsToHms(secondsPassedCurrentCall) +
          '/' + secondsToHms(pastTimeInSeconds + secondsPassedCurrentCall);
      }, 1000);
    }
  }

  private CaculatePastHoldDuration(): number {
    let pastTimeInSeconds = 0;
    if (this.holdCounterData.pastCallDurations) {
      this.holdCounterData.pastCallDurations.forEach((holdTimerIterator: ICallDuration) => {
        pastTimeInSeconds += (holdTimerIterator.endTime - holdTimerIterator.startTime) / 1000;
      });
    }
    return pastTimeInSeconds;

  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    // clear the timer.
    if (this._timerId != null) {
      clearInterval(this._timerId);
      this._timerId = null;
    }
  }

}
