/**
 * @packageDocumentation
 * @module DaVinci_API
 */

/**
 * Standard error codes
 * Used by [[Logger]]
 */
export enum ERROR_CODE {
  Other = 0,

  UNAUTHORIZED = 1001,

  INCORRECT_PASSWORD = 2001,
  USERID_NOT_FOUND = 2002,

  PRESENCE_CHANGE = 3005,
  LOGIN = 3006,
  LOGOUT = 3007,
  APP_INITIALIZED = 3008,
  ALERTING_INTERACTION = 3009,
  CONNECTED_INTERACTION = 3010,
  DISCONEECTED_INTERACTION = 3011,
  ONHOLD_INTERACTION = 3012,
  INTERACTION_EVENT = 3013,

  REDUCER_EVENT = 3020,
  ACTIVITY = 3021,
  SCREEN_POP = 3022,
  SEARCH_RECORD = 3023,

  DUPLICATE_RECORD = 5001,
  INITIALIZE_COMPLETE_ERROR = 5002
}
